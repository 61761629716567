import {
  EnvironmentProviders,
  makeEnvironmentProviders,
  NgModule,
} from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { MapLoadingService } from './map-loading.service';

export function provideLoadingService(): EnvironmentProviders {
  return makeEnvironmentProviders([MapLoadingService]);
}

@NgModule({
  imports: [HttpClientModule, HttpClientJsonpModule],
  exports: [HttpClientModule, HttpClientJsonpModule],
  providers: [MapLoadingService],
})
export class MapLoadingServiceModule {}
