import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export type HeaderType = 'transparent' | 'landlord' | 'tenant';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private static DEFAULT_SAVE_PAD_VISIBILITY = false;
  private static DEFAULT_VIEWINGS_VISIBILITY = false;
  private static DEFAULT_POST_A_PAD_VISIBILITY = true;
  private static DEFAULT_BROWSE_PADS_VISIBILITY = true;
  private bg$ = new BehaviorSubject<HeaderType>('transparent');
  private contrast$ = new BehaviorSubject<'green' | 'blue'>('green');
  private savePadBtnVisible$ = new BehaviorSubject<boolean>(
    HeaderService.DEFAULT_SAVE_PAD_VISIBILITY
  );
  private viewingsBtnVisible$ = new BehaviorSubject<boolean>(
    HeaderService.DEFAULT_VIEWINGS_VISIBILITY
  );
  private isPostAPadBtnVisible$ = new BehaviorSubject<boolean>(
    HeaderService.DEFAULT_POST_A_PAD_VISIBILITY
  );
  private isBrowsePadsBtnVisible$ = new BehaviorSubject<boolean>(
    HeaderService.DEFAULT_BROWSE_PADS_VISIBILITY
  );
  private isTenant$ = new BehaviorSubject<boolean>(false);
  private isLanding$ = new BehaviorSubject<boolean>(false);

  public readonly bgColor$: Observable<HeaderType> = this.bg$.asObservable();
  public readonly contrastColor$: Observable<'green' | 'blue'> =
    this.contrast$.asObservable();
  public readonly savePadsVisible$ = this.savePadBtnVisible$.asObservable();
  public readonly viewingsVisible$ = this.viewingsBtnVisible$.asObservable();
  public readonly isPostAPadVisible$ =
    this.isPostAPadBtnVisible$.asObservable();
  public readonly isBrowsePadsVisible$ =
    this.isBrowsePadsBtnVisible$.asObservable();
  public readonly isTenantObvs$ = this.isTenant$.asObservable();
  public readonly isLandingObvs$ = this.isLanding$.asObservable();
  constructor(private readonly router: Router) {
    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => this.reset());
  }

  private reset(): void {
    this.savePadBtnVisible$.next(HeaderService.DEFAULT_SAVE_PAD_VISIBILITY);
    this.viewingsBtnVisible$.next(HeaderService.DEFAULT_VIEWINGS_VISIBILITY);
    this.isPostAPadBtnVisible$.next(
      HeaderService.DEFAULT_POST_A_PAD_VISIBILITY
    );
    this.isBrowsePadsBtnVisible$.next(
      HeaderService.DEFAULT_BROWSE_PADS_VISIBILITY
    );
  }

  public setType(
    type: HeaderType = 'transparent',
    contrastColor?: 'green' | 'blue',
    isTenant = false,
    isLanding = false
  ): void {
    this.bg$.next(type);
    this.contrast$.next(
      contrastColor ? contrastColor : type === 'tenant' ? 'blue' : 'green'
    );
    this.isTenant$.next(isTenant);
    this.isLanding$.next(isLanding);
  }

  public setSavedPadsButtonVisibility(visible: boolean): void {
    this.savePadBtnVisible$.next(visible);
  }

  public setViewingsButtonVisibility(visible: boolean): void {
    this.viewingsBtnVisible$.next(visible);
  }

  public setPostAPadButtonVisibility(visible: boolean): void {
    this.isPostAPadBtnVisible$.next(visible);
  }

  public setBrowsePadsButtonVisibility(visible: boolean): void {
    this.isBrowsePadsBtnVisible$.next(visible);
  }
}
