import { UserRecord } from 'firebase-admin/auth';
import { CustomClaims } from '@padspin/rbac';

export const listUsersName = 'list_users' as const;

export type ListUsersRequestData = {
  currentUserIdToken: string;
  /** Optionally filter users by a customClaim */
  customClaim?: keyof CustomClaims;
};

export type ListUsersResponse = {
  users: Array<UserRecord>;
};
