import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private isShown = new BehaviorSubject(true);
  visible$: Observable<boolean> = this.isShown.asObservable();

  constructor(private router: Router) {
    // When we load a new route, show the footer by default
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isShown.next(true);
      }
    });
  }

  getVisibility(): Observable<boolean> {
    return this.isShown.asObservable();
  }

  setVisibility(isFooterVisible: boolean): void {
    this.isShown.next(isFooterVisible);
  }
}
