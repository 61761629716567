import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import {
  PixelConfiguration,
  PixelEventName,
  PixelEventProperties,
} from './facebook-pixel.type';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const fbq: (
  type: 'track' | 'trackCustom',
  eventName: string,
  properties?: unknown
) => void;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  private renderer: Renderer2;

  constructor(
    @Inject('facebookPixelConfig') private readonly config: PixelConfiguration,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(PLATFORM_ID)
    private readonly platformId: Parameters<typeof isPlatformBrowser>[0],
    private readonly rendererFactory: RendererFactory2,
    @Optional() private readonly router?: Router
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    if (this.router) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((_event) => {
          if (this.isLoaded()) {
            this.track('PageView');
          }
        });
    }
  }

  initialize(pixelId = this.config.pixelId): void {
    if (this.isLoaded()) {
      console.warn(
        'Tried to initialize a Pixel instance while another is already active. Please call `remove()` before initializing a new instance.'
      );
      return;
    }
    this.config.enabled = true;
    this.addPixelScript(pixelId);
  }

  remove(): void {
    this.removePixelScript();
    this.config.enabled = false;
  }

  track(eventName: PixelEventName, properties?: PixelEventProperties): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.isLoaded()) {
      console.warn(
        'Tried to track an event without initializing a Pixel instance. Call `initialize()` first.'
      );
      return;
    }
    if (properties) {
      fbq('track', eventName, properties);
    } else {
      fbq('track', eventName);
    }
  }

  /**
   * Track a custom Event
   * See {@link https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-conversions Facebook Pixel docs - custom conversions}
   */
  trackCustom(eventName: string, properties?: unknown): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.isLoaded()) {
      console.warn(
        'Tried to track an event without initializing a Pixel instance. Call `initialize()` first.'
      );
      return;
    }
    if (properties) {
      fbq('trackCustom', eventName, properties);
    } else {
      fbq('trackCustom', eventName);
    }
  }

  private addPixelScript(pixelId: string): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const pixelCode = `
    var pixelCode = function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');`;
    const scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(scriptElement, 'id', 'pixel-script');
    this.renderer.setAttribute(scriptElement, 'type', 'text/javascript');
    this.renderer.setProperty(scriptElement, 'innerHTML', pixelCode);
    this.renderer.appendChild(this.document.head, scriptElement);
  }

  private removePixelScript(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const pixelElement = this.document.getElementById('pixel-script');
    if (pixelElement) {
      pixelElement.remove();
    }
  }

  private isLoaded(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const pixelElement = this.document.getElementById('pixel-script');
      return !!pixelElement;
    }
    return false;
  }
}
