export type ListingPostToExternalPlatformRequestData = {
  slug: string;
  platform:
    | 'facebook'
    | 'linkedin'
    | 'craigslist'
    | 'twitter'
    | 'apartments_dot_com'
    | 'zillow';
};

export type ListingPostToExternalPlatformResponse = {
  success: boolean;
  message?: string;
};

export const listing_post_to_external_platform_fn_name =
  'listing_post_to_external_platform' as const;
