import { CustomClaims } from '@padspin/rbac';

export const getUserCustomClaimsName = 'get_user_custom_claims' as const;

export type GetUserCustomClaimsRequestData = {
  uid?: string;
  phone?: string;
  email?: string;
};
export type GetUserCustomClaimsResponse = CustomClaims;
