/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-selector */

import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';

const BUTTON_HOST_ATTRIBUTES = [
  'padspin-button',
  'padspin-secondary-button',
  'padspin-tertiary-button',
  'padspin-fab-button',
];

/**
 * @example
 * ```
 * <button padspin-button>Hello</button>
 * <button padspin-secondary-button [disabled]="true">Hello</button>
 * <button padspin-tertiary-button color="'blue'">Hello</button>
 * <button padspin-fab-button><img icon src="assets/icons/close.svg" alt="Close." /></button>
 * ```
 */
@Component({
  selector: `button[padspin-button], button[padspin-secondary-button], button[padspin-tertiary-button], button[padspin-fab-button]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'padspinButton',
  imports: [CommonModule],
  standalone: true,
})
export class PadspinButtonComponent {
  #disabled = false;
  #color: 'landlord' | 'tenant' | 'blue' | 'green' = 'landlord';
  #shadow = false;
  #hoverColor: 'green' | 'blue' = 'blue';
  #center = false;
  #formGroup: UntypedFormGroup | undefined = undefined;

  @HostBinding('class.mat-button') hasMatButtonClass = true;

  @HostBinding('class.padspin-button-disabled')
  @HostBinding('attr.disabled')
  @Input()
  set disabled(value: any) {
    this.#disabled = value != null && `${value}` !== 'false';
  }
  get disabled(): any {
    return this.#disabled ? true : null;
  }

  /** 'landlord' is blue, 'tenant' is green. */
  @HostBinding('attr.color')
  @Input()
  set color(value: 'landlord' | 'tenant' | 'blue' | 'green') {
    if (this.#color) {
      // remove current color class
      this.getHostElement().classList.remove(`padspin-${this.#color}-color`);
    }
    let setColor: 'landlord' | 'tenant';
    if (value === 'blue') {
      setColor = 'landlord';
    } else if (value === 'green') {
      setColor = 'tenant';
    } else {
      setColor = value;
    }
    this.getHostElement().classList.add(`padspin-${setColor}-color`);
    this.#color = setColor;
  }
  get color(): 'landlord' | 'tenant' | 'blue' | 'green' {
    return this.#color;
  }

  @Input('color') set myColor(value: 'landlord' | 'tenant' | 'blue' | 'green') {
    this.color = value;
  }

  @HostBinding('attr.hoverColor')
  @Input()
  set hoverColor(color: 'green' | 'blue') {
    if (this.#hoverColor) {
      this.getHostElement().classList.remove(
        `padspin-${this.#hoverColor}-hover-color`
      );
    }
    if (!color) {
      this.#hoverColor = color;
      return;
    }
    this.getHostElement().classList.add(`padspin-${color}-hover-color`);
    this.#hoverColor = color;
  }
  get hoverColor(): 'green' | 'blue' {
    return this.#hoverColor;
  }

  @Input('hoverColor') set myHoverColor(color: 'green' | 'blue') {
    this.hoverColor = color;
  }

  /**
   * Whether the button has a shadow.
   * @example
   * <a padspin-button shadow>button_with_a_shadow</a>
   */
  @HostBinding('class.padspin-shadow')
  @HostBinding('attr.shadow')
  @Input()
  set shadow(value: any) {
    this.#shadow = value != null && `${value}` !== 'false';
  }
  get shadow(): any {
    return this.#shadow ? true : null;
  }

  @HostBinding('class.padspin-text-center')
  @HostBinding('attr.text-center')
  @Input()
  set center(value: any) {
    this.#center = value != null && `${value}` !== 'false';
  }
  get center(): any {
    return this.#center ? true : null;
  }

  #iconWidth: 22 | 24 | 34 = 24;
  @HostBinding('attr.iconSize')
  @Input()
  set iconWidth(widthInPx: 22 | 24 | 34) {
    this.getHostElement().classList.remove(
      `padspin-${this.#iconWidth}-icon-width`
    );
    this.getHostElement().classList.add(`padspin-${widthInPx}-icon-width`);
    this.#iconWidth = widthInPx;
  }

  @Input()
  set formGroup(value: UntypedFormGroup | undefined) {
    this.#formGroup = value;
  }
  get formGroup() {
    return this.#formGroup;
  }

  constructor(public elementRef: ElementRef) {
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        (this.getHostElement() as HTMLElement).classList.add(attr);
      }
    }
    this.getHostElement().classList.add(this.#color);
  }

  getHostElement(): HTMLAnchorElement {
    return this.elementRef.nativeElement;
  }

  hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some((attribute) =>
      this.getHostElement().hasAttribute(attribute)
    );
  }

  onClick() {
    if (this.formGroup !== undefined) {
      this.formGroup.markAllAsTouched();
      return;
    }
    console.log('FormGroup is undefined');
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: `a[padspin-button], a[padspin-secondary-button], a[padspin-tertiary-button], a[padspin-fab-button]`,
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'padspinButton, padspinAnchor',
  standalone: true,
  imports: [CommonModule],
})
export class PadspinAnchorComponent {
  #disabled = false;
  #color: 'landlord' | 'tenant' = 'landlord';
  #shadow = false;

  @HostBinding('class.padspin-button-disabled')
  @HostBinding('attr.disabled')
  @Input()
  set disabled(value: any) {
    this.#disabled = value != null && `${value}` !== 'false';
  }
  get disabled(): any {
    return this.#disabled ? true : null;
  }

  /** 'landlord' is blue, 'tenant' is green. */
  @HostBinding('attr.color')
  @Input()
  set color(value: 'landlord' | 'tenant' | 'blue' | 'green') {
    if (this.#color) {
      // remove current color class
      this.getHostElement().classList.remove(`padspin-${this.#color}-color`);
    }
    let setColor: 'landlord' | 'tenant';
    if (value === 'blue') {
      setColor = 'landlord';
    } else if (value === 'green') {
      setColor = 'tenant';
    } else {
      setColor = value;
    }
    this.getHostElement().classList.add(`padspin-${setColor}-color`);
    this.#color = setColor;
  }
  get color(): 'landlord' | 'tenant' | 'blue' | 'green' {
    return this.#color;
  }

  @Input('color') set myColor(value: 'landlord' | 'tenant' | 'blue' | 'green') {
    this.color = value;
  }

  /**
   * Whether the link has a shadow.
   * @example
   * <a padspin-button shadow>button_with_a_shadow</a>
   */
  @HostBinding('class.padspin-shadow')
  @HostBinding('attr.shadow')
  @Input()
  set shadow(value: any) {
    this.#shadow = value != null && `${value}` !== 'false';
  }
  get shadow(): any {
    return this.#shadow ? true : null;
  }

  constructor(public elementRef: ElementRef) {
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this.hasHostAttributes(attr)) {
        (this.getHostElement() as HTMLElement).classList.add(attr);
      }
    }
    this.getHostElement().classList.add(`padspin-${this.#color}-color`);
  }

  onClick() {
    // No op
  }

  getHostElement(): HTMLAnchorElement {
    return this.elementRef.nativeElement;
  }

  hasHostAttributes(...attributes: string[]): boolean {
    return attributes.some((attribute) =>
      this.getHostElement().hasAttribute(attribute)
    );
  }
}
