export type SendRentalApplicationFillRequest = {
  email_body: string;
  email_subject: string;
  email_recipient: string;
  email_sender: string;
  phone_number: string;
  sms_body: string;
  account_manager_email: string;
};

export type SendRentalApplicationFillResponse = { success: boolean };
export const rental_app_fill_request_msg_name =
  'rental_app_fill_request_msg' as const;
