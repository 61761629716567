import { inject, Injectable } from '@angular/core';
import { LoginOrRegisterInputType } from '../../modals/login-or-register-modal/login-or-register-input.type';
import { UserCredential } from '@angular/fire/auth';
import { firstValueFrom } from 'rxjs';
import {
  LazilyLoadedModals,
  ModalLazyLoaderService,
} from '../../modal-lazy-loader.service';

@Injectable({
  providedIn: 'root',
})
export class AttemptLoginService {
  private readonly mlls = inject(ModalLazyLoaderService);

  async attemptLogin(
    data: LoginOrRegisterInputType = {}
  ): Promise<UserCredential | undefined> {
    const dialogRef = await this.mlls.openModal<UserCredential>(
      LazilyLoadedModals.LoginOrRegisterModalComponent,
      {
        data,
        backdropClass: 'modal_backdrop',
        disableClose: true,
      }
    );
    try {
      return await firstValueFrom(dialogRef.afterClosed());
    } catch (error) {
      return;
    }
  }
}
