<aside [class.hidden]="!isOpen">
  <button
    padspin-fab-button
    [iconWidth]="22"
    aria-label="close"
    (click)="close()"
  >
    <img src="/assets/web/icons/close.svg" alt="close" />
  </button>
  <nav>
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/landlord"
      *ngIf="isPostAPadVisible$ | async"
      >Post Pad</a
    >
    <a padspin-tertiary-button (click)="close()" routerLink="/criteria"
      >Matching Criteria</a
    >
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/leasebreak"
      *ngIf="(isLeaseBreakLandingVisible$ | async) === false"
      >Breaking My Lease</a
    >
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/dashboard/landlord"
      *ngIf="isLandlordDashboardVisible$ | async"
      >Landlord Dashboard</a
    >
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/dashboard/tenant"
      *ngIf="isTenantDashboardVisible$ | async"
      >Tenant Dashboard</a
    >
    <a
      padspin-tertiary-button
      (click)="close()"
      routerLink="/dashboard/admin"
      *ngIf="isAdminDashboardVisible$ | async"
      >Admin Dashboard</a
    >
    <a
      padspin-tertiary-button
      (click)="login()"
      *ngIf="(user$ | async) === null"
      >Sign-Up / Login</a
    >
    <a
      padspin-tertiary-button
      (click)="logout()"
      *ngIf="(user$ | async) !== null"
      >Logout</a
    >
    <a
      padspin-tertiary-button
      (click)="register()"
      *ngIf="(user$ | async) === null"
      >Start Matching</a
    >
  </nav>
</aside>
