<section
  class="notch"
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
></section>
<header
  [class.landlord]="bg === 'landlord'"
  [class.tenant]="bg === 'tenant'"
  [class.transparent]="bg === 'transparent'"
>
  <a routerLink="/" role="heading">
    <img
      *ngIf="(bg === 'transparent' || bg === 'landlord') && !isLanding"
      src="/assets/web/padspin-logo-white-green-wordmark.svg"
      alt="Padspin"
      (click)="onLogoClick()"
    />
    <img
      *ngIf="bg === ('tenant' || isTenant) && !isLanding"
      src="/assets/web/padspin-logo-white-blue-wordmark.svg"
      alt="Padspin"
    />
    <img
      *ngIf="isLanding"
      src="/assets/web/padspin-logo-green-blue-wordmark.svg"
      alt="Padspin"
    />
  </a>
  <button
    class="leasebreak-button"
    padspin-secondary-button
    routerLink="/leasebreak"
    routerLinkActive="hide"
    *ngIf="isLandingVisible$ | async"
  >
    Breaking My Lease
  </button>
  <button
    class="landlord-button"
    padspin-button
    color="green"
    routerLink="/landlord"
    routerLinkActive="hide"
    *ngIf="isLandingVisible$ | async"
    hoverColor="green"
  >
    I'm a Landlord
  </button>
  <button
    class="post-pad-button"
    padspin-button
    [color]="contrastColor"
    routerLink="/post-a-pad"
    routerLinkActive="hide"
    *ngIf="isLandlordLandingVisible$ | async"
    (click)="onPostPadClick()"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Post Pad
  </button>
  <button
    padspin-tertiary-button
    *ngIf="isSavedPadsVisible$ | async"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Saved Pads
  </button>
  <button
    padspin-tertiary-button
    *ngIf="isViewingsVisible$ | async"
    [hoverColor]="isTenant ? 'blue' : 'green'"
  >
    Viewings
  </button>

  <ng-container *ngIf="primengMenuItems$ | async as primengMenuItems">
    <button
      padspin-fab-button
      class="profile-button desktop-only"
      [iconWidth]="24"
      (click)="primengMenu.toggle($event)"
    >
      <img
        icon
        src="/assets/web/icons/perm-identity-white.svg"
        alt="Profile Menu"
      />
    </button>

    <p-menu #primengMenu [popup]="true" [model]="primengMenuItems"></p-menu>
  </ng-container>
  <button
    padspin-fab-button
    class="mobile-only"
    [iconWidth]="34"
    (click)="toggleMenu()"
  >
    <img icon src="/assets/web/icons/menu-black.svg" alt="Menu" />
  </button>
</header>
