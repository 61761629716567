import {
  Inject,
  ModuleWithProviders,
  NgModule,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PixelConfiguration } from './facebook-pixel.type';
import { FacebookPixelService } from './facebook-pixel.service';

@NgModule({})
export class FacebookPixelModule {
  private static config: PixelConfiguration | null = null;

  constructor(
    private pixel: FacebookPixelService,
    @Inject(PLATFORM_ID) platformId: Parameters<typeof isPlatformBrowser>[0]
  ) {
    if (!FacebookPixelModule.config) {
      throw new Error(
        'Facebook Pixel configuration is not set. Pass the `pixelId` property to `forRoot()`'
      );
    }
    if (FacebookPixelModule.config.enabled && isPlatformBrowser(platformId)) {
      this.pixel.initialize();
    }
  }

  /** Initialize the Facebook Pixel Module with your Pixel ID */
  static forRoot(
    config: PixelConfiguration
  ): ModuleWithProviders<FacebookPixelModule> {
    this.config = config;
    const pixelId = config.pixelId;
    if (!FacebookPixelModule.isPixelIdValid(pixelId)) {
      throw new Error(
        'Facebook Pixel ID is not valid. Please provide a valid Pixel ID'
      );
    }

    return {
      ngModule: FacebookPixelModule,
      providers: [
        FacebookPixelService,
        { provide: 'facebookPixelConfig', useValue: config },
      ],
    };
  }

  private static isPixelIdValid(pixelId: string): boolean {
    return pixelId !== null && pixelId !== undefined && pixelId !== '';
  }
}
