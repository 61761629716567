import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireRemoteConfigModule } from '@angular/fire/compat/remote-config';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FrontendComponent } from './frontend.component';
import { webEnvironment } from '../../environments/environment';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FacebookPixelModule } from '../facebook-pixel/facebook-pixel.module';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { MapLoadingServiceModule } from '../map-loading-service/map-loading-service.module';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

const globalImports: NgModule['imports'] = [
  BrowserModule,
  BrowserAnimationsModule,
  FacebookPixelModule.forRoot({ enabled: true, pixelId: '311269089285285' }),
  AngularFireModule.initializeApp(webEnvironment.firebase),
  provideFirebaseApp(() => initializeApp(webEnvironment.firebase)),
  provideFunctions(() => getFunctions()),
  AngularFireFunctionsModule,
  provideFirestore(() => getFirestore()),
  AngularFireAuthModule,
  provideAuth(() => getAuth()),
  provideStorage(() => getStorage()),
  ScrollingModule,
  AngularFireRemoteConfigModule,
  AngularFireAnalyticsModule,
  MatLegacySnackBarModule,
  MapLoadingServiceModule,
  HttpClientModule,
  HttpClientJsonpModule,
];

@NgModule({
  imports: [...globalImports, FrontendComponent],
  providers: [],
  exports: [FrontendComponent],
})
export class FrontendModule {}
