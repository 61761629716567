import { TenantCriteriaModel } from '@padspin/models';

export const updateMatchCriteriaName = 'update_match_criteria' as const;

export type UpdateMatchCriteriaRequestData = Pick<
  Partial<TenantCriteriaModel>,
  | 'max_rent'
  | 'credit_score'
  | 'bedrooms'
  | 'earliest_move_in_date'
  | 'funds_available'
  | 'guarantor'
  | 'annual_income'
> & {
  locations?: Array<{ place_id: string }>;
};

export type UpdateMatchCriteriaResponse =
  | TenantCriteriaModel
  | { success: boolean; error?: unknown };
