import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Capacitor } from '@capacitor/core';
import {
  AppUpdate,
  AppUpdateAvailability,
} from '@robingenz/capacitor-app-update';

@Component({
  selector: 'padspin-version',
  template: `
    <div mat-dialog-content>
      <p>Your version of Padspin is out-of-date. Update to continue.</p>
    </div>
    <div mat-dialog-actions>
      <button padspin-button (click)="visitAppStore()">Update</button>
    </div>
  `,
})
export class VersionComponent {
  window: (Window & typeof globalThis) | null;

  constructor(
    private readonly dialogRef: MatDialogRef<VersionComponent>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.window = this.document.defaultView;
  }

  async visitAppStore() {
    const platform = Capacitor.getPlatform() as 'ios' | 'android' | 'web';
    if (platform === 'ios') {
      await AppUpdate.openAppStore();
    } else if (platform === 'android') {
      const result = await AppUpdate.getAppUpdateInfo();
      if (
        result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE
      ) {
        return this.dialogRef.close();
      }
      if (result.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate();
      }
    } else {
      // Reload to get latest version
      this.window?.location.reload();
    }
    // TODO: Open the app store with the Capacitor addon
    return this.dialogRef.close();
  }
}
