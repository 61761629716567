import { Injectable } from '@angular/core';
import { BehaviorSubject, delay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  readonly isLoading$ = this._isLoading$.asObservable().pipe(delay(1));
  show(): void {
    this._isLoading$.next(true);
  }
  hide(): void {
    this._isLoading$.next(false);
  }
}
