import { inject, Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  type MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import type { ComponentType } from '@angular/cdk/overlay';
import { defer, firstValueFrom, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialogConfig } from '@angular/material/dialog';

/**
 * Map between each modal component's name, and it's path relative to the modals
 * directory, minus ".component.ts" so that WebPack can create chunks for each
 * file in the modals directory that ends in ".component.ts".
 *
 * We can add every modal in the app to this enum, and then use it to load the
 * modal lazily _without_ having to include the transitive dependencies of all
 * modals in the root module.
 *
 * Ensure these components are not imported into this file, or they will be
 * transitively included in the bundle.
 */
export const enum LazilyLoadedModals {
  CredentialEditorModalComponent = 'CredentialEditorModalComponent',
  DownloadImagesModalComponent = 'DownloadImagesModalComponent',
  ExperianTutorialModalComponent = 'ExperianTutorialModalComponent',
  LoginOrRegisterModalComponent = 'LoginOrRegisterModalComponent',
  ListingEditModalComponent = 'ListingEditModalComponent',
  SendApplicationModalComponent = 'SendApplicationModalComponent',
  UserDetailsModalComponent = 'UserDetailsModalComponent',
  OnboardingModalComponent = 'OnboardingModalComponent',
}

/**
 * Open modal dialogs _without_ including them and their transitive dependencies
 * in the bundle.
 *
 * This service requires the `tsconfig.lib.json`.includes to include the
 * directory with the modals. All modals loaded by this service must be in the
 * `modals` directory.
 *
 * ```json
 * {
 *   "include": ["**\/*.ts", "**\/*modal.component.ts"]
 * }
 * ```
 *
 * @usageNotes
 * Open a modal dialog:
 * ```typescript
 * const mlls = inject(ModalLazyLoaderService);
 * const data = { uid: '123' };
 * const dialogRef = await mlls.openModal<void>(
 *   LazilyLoadedModalsEnum.UserDetailsModalComponent,
 *   data
 * );
 * const output = await firstValueFrom(dialogRef.afterClosed());
 * ```
 *
 * Adding a new modal:
 * ```typescript
 * // Create the modal component, it myst be standalone.
 * @Component({
 *   standalone: true,
 *   template: `<button (click)="close()">Close</button>`,
 * }) export class MyModal {
 *   close(): void { inject(MatDialogRef<MyModal>).close({ foo: 'bar' }); }
 * };
 *
 * // Add the name and path to the `LazilyLoadedModals` enum. Exclude '.ts'.
 * export const enum LazilyLoadedModals {
 *   ...
 *   MyModalComponent = './my-modal.component',
 * }
 *
 * // Use the modal in your code.
 * import {
 *   ModalLazyLoaderService,
 *   LazilyLoadedModals
 * } from './modal-lazy-loader.service';
 * @Injectable() export class SomeService {
 *   async openModalWithoutImportingIt(): Promise<void> {
 *     const mlls = inject(ModalLazyLoaderService);
 *     const dr = await mlls.openModal<{ foo: string }>(LazilyLoadedModals.MyModal, data);
 *     const res: { foo: string } = await firstValueFrom(dr.afterClosed());
 *   }
 * }
 * ``
 */
@Injectable({ providedIn: 'root' })
export class ModalLazyLoaderService {
  private dialog = inject(MatDialog);

  /**
   * Open a modal dialog without including it, and it's transitive dependencies
   * in the application bundle.
   *
   * @param modal Modal to open. Generic type `Ret` is your `DialogRef`
   * return type.
   * @param config The `MatDialogConfig` to pass to the modal.
   */
  async openModal<Ret, Dat = unknown>(
    modal: LazilyLoadedModals,
    config: MatDialogConfig<Dat>
  ): Promise<MatDialogRef<unknown, Ret>> {
    return firstValueFrom(this.openModal$(modal, config));
  }

  /**
   * Open a modal dialog without including it (or its transitive dependencies)
   * in the bundle. Share the observable so that the modal is only loaded once.
   * @param modal Modal to open. Generic type `Ret` is your `DialogRef`
   * return type.
   * @param config The `MatDialogConfig` to pass to the modal.
   */
  openModal$<Ret, Dat = unknown>(
    modal: LazilyLoadedModals,
    config: MatDialogConfig<Dat>
  ): Observable<MatDialogRef<unknown, Ret>> {
    return defer(() => this.loadModalComponent$(modal)).pipe(
      map((component) => this.openDialog<Dat, Ret>(component, config)),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private loadModalComponent$(
    modal: LazilyLoadedModals
  ): Observable<ComponentType<unknown>> {
    return defer(async () => {
      // Use a switch statement so WebPack can create a chunk for each modal.
      // Trying to be clever and using a map will not work because WebPack
      // will not be able to statically analyze the map.
      switch (modal) {
        case LazilyLoadedModals.CredentialEditorModalComponent:
          return await import(
            './modals/credential-editor-modal/credential-editor-modal.component'
          );
        case LazilyLoadedModals.DownloadImagesModalComponent:
          return await import(
            './modals/download-images-modal/download-images-modal.component'
          );
        case LazilyLoadedModals.ExperianTutorialModalComponent:
          return await import(
            './modals/experian-tutorial-modal/experian-tutorial-modal.component'
          );
        case LazilyLoadedModals.LoginOrRegisterModalComponent:
          return await import(
            './modals/login-or-register-modal/login-or-register-modal.component'
          );
        case LazilyLoadedModals.ListingEditModalComponent:
          return await import(
            './modals/listing-edit-modal/listing-edit-modal.component'
          );
        case LazilyLoadedModals.SendApplicationModalComponent:
          return await import(
            './modals/send-application-modal/send-application-modal.component'
          );
        case LazilyLoadedModals.UserDetailsModalComponent:
          return await import(
            './modals/user-details-modal/user-details-modal.component'
          );
        case LazilyLoadedModals.OnboardingModalComponent:
          return await import(
            './modals/onboarding-modal/onboarding-modal.component'
          );
      }
      // If we add a modal, the build should fail because not all code paths
      // return a value.
    }).pipe(
      map((chunk) => Object.values(chunk)[0] as ComponentType<unknown>),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  private openDialog<Dat, Ret>(
    component: ComponentType<unknown>,
    config: MatDialogConfig<Dat>
  ): MatDialogRef<unknown, Ret> {
    return this.dialog.open<unknown, Dat, Ret>(component, config);
  }
}
