import { CustomClaims } from '@padspin/rbac';

export const getUserTypeName = 'get_user_type' as const;

/**
 * Must include at least one of uid, email, or phone.
 */
export type GetUserTypeRequestData = {
  uid?: string;
  phone?: string;
  email?: string;
};
export type GetUserTypeResponse = {
  type: 'tenant' | 'landlord';
  admin: boolean;
} & CustomClaims;
