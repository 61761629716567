import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontendModule } from './frontend/frontend.module';

@Component({
  selector: 'padspin-web',
  template: '<padspin-frontend></padspin-frontend>',
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
})
export class WebComponent {}

@NgModule({
  imports: [CommonModule, FrontendModule],
  declarations: [WebComponent],
  exports: [WebComponent, FrontendModule],
})
export class WebModule {}
