import { Component, OnInit } from '@angular/core';
import { asyncScheduler, observeOn } from 'rxjs';
import { FooterService } from '../footer.service';
import { LoadingService } from '../loading.service';
import { VersionService } from '../version.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { HeaderModule } from '../header/header.module';
import { SideNavModule } from '../side-nav/side-nav.module';
import { FooterModule } from '../footer/footer.module';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { FrontendRoutingModule } from './frontend-routing.module';
import { CommonModule, DatePipe } from '@angular/common';
import { VersionModule } from '../version/version.module';
import { DEFAULTS } from '@angular/fire/compat/remote-config';
import {
  COLLECTION_ENABLED,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'padspin-frontend',
  template: `
    <padspin-header></padspin-header>
    <padspin-side-nav></padspin-side-nav>
    <section class="content">
      <ng-container *ngIf="isLoading$ | async">
        <mat-progress-bar
          mode="indeterminate"
          color="accent"
        ></mat-progress-bar>
      </ng-container>
      <router-outlet></router-outlet>
    </section>
    <padspin-footer *ngIf="isFooterVisible$ | async"></padspin-footer>
  `,
  styleUrls: ['./frontend.component.scss'],
  standalone: true,
  imports: [
    HeaderModule,
    SideNavModule,
    FooterModule,
    MatProgressBarModule,
    FrontendRoutingModule,
    CommonModule,
    VersionModule,
  ],
  providers: [
    // { provide: MatDialogRef, useValue: {} },
    {
      provide: DEFAULTS,
      useValue: { show_book_a_pad_form_before_register_form: true },
    },
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: COLLECTION_ENABLED,
      useValue: Capacitor.getPlatform() === 'web',
    },
  ],
})
export class FrontendComponent implements OnInit {
  isFooterVisible$ = this.footerService.visible$.pipe(
    observeOn(asyncScheduler)
  );
  isLoading$ = this.loadingService.isLoading$;

  constructor(
    private readonly footerService: FooterService,
    private readonly loadingService: LoadingService,
    private readonly versionService: VersionService,
    private readonly router: Router,
    private readonly analytics: GoogleAnalyticsService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const navigationEvent = event as NavigationEnd;
        // This is an experiment validating how it impacts the audiences in Google Ads
        this.analytics.gtag('event', 'page_view', {
          page_path: navigationEvent.urlAfterRedirects,
        });
        this.analytics.gtagConfig('AW-10987733817', {
          page_path: navigationEvent.urlAfterRedirects,
        });
      });
  }

  async ngOnInit(): Promise<void> {
    await this.versionService.checkMinVersion();
  }
}
