export const rental_app_notify_applicant_of_decision_name =
  'rental_app_notify_applicant_of_decision' as const;

export type RentalAppNotifyApplicantOfDecisionRequest = {
  applicationId: string;
  approved: boolean;
};

export type RentalAppNotifyApplicantOfDecisionResponse = {
  success: boolean;
};
