export const createUserName = 'create_user' as const;

export type CreateUserRequestData = {
  email: string;
  password: string;
  phone: string;
  type: 'tenant' | 'landlord';
  first_name: string;
  last_name: string;
};

export type CreateUserResponse = {
  success: boolean;
  error?: { code: string; message: string };
};
