import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionComponent } from './version.component';
import { PadspinButtonComponent } from '@padspin/ui-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { VersionService } from '../version.service';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [VersionComponent],
  imports: [CommonModule, PadspinButtonComponent, MatDialogModule, A11yModule],
  exports: [VersionComponent],
  providers: [VersionService],
})
export class VersionModule {}
