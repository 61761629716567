import { UserProfile, UserProfileID } from '@padspin/models';

export const createProfileName = 'create_profile' as const;

export type CreateProfileRequestData = {
  uid?: string;
  phone?: string;
  email?: string;
  profile: Partial<UserProfile> & Pick<Required<UserProfile>, 'type'>;
};

export type CreateProfileResponse = UserProfileID;
