import { CustomClaims } from '@padspin/rbac';

export const changeUserTypeName = 'change_user_type' as const;

export type ChangeUserTypeRequestData = {
  /** User ID to change type of */
  uid: string;
  /** Should the user be a landlord or a tenant? Unchanged if null. */
  type: 'landlord' | 'tenant' | null;
  /** Should the user be an admin? Unchanged if null. */
  admin: boolean | null;
  /** Should the user be an owner? Unchanged if null. */
  owner: boolean | null;
  /** Should the user be an account manager? Unchanged if null. */
  account_manager: boolean | null;
  /** idToken of currentUser (`await currentUser.getIdToken(true)`) */
  currentUserIdToken: string;
};

export type ChangeUserTypeResponse = {
  claims: Partial<CustomClaims>;
};
