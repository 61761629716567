import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

import { WebModule } from '@padspin/web';
import { FrontendModule } from '@padspin/web';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FrontendModule,
    WebModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
