import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavComponent } from './side-nav.component';
import {
  PadspinAnchorComponent,
  PadspinButtonComponent,
} from '@padspin/ui-button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [SideNavComponent],
  exports: [SideNavComponent],
  imports: [
    CommonModule,
    PadspinButtonComponent,
    RouterModule,
    PadspinAnchorComponent,
  ],
})
export class SideNavModule {}
