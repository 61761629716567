import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  private state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public toggleSideNav(state?: 'open' | 'close'): void {
    if (state === undefined) {
      this.state.next(!this.state.getValue());
    } else {
      this.state.next(state === 'open');
    }
  }

  /** Is the menu open? */
  public isOpen(): Observable<boolean> {
    return this.state.asObservable();
  }
}
