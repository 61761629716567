export const deleteUserName = 'delete_user' as const;

export type DeleteUserRequestData = {
  /** User to delete */
  uid: string;
  /** idToken of current user. Only an admin can delete an account. */
  currentUserIdToken: string;
};

export type DeleteUserResponse = {
  uid: string;
};
