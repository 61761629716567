import { FirebaseOptions } from '@angular/fire/app';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare function require(moduleName: string): any;

export const webEnvironment = {
  googleMapsApiKey: 'AIzaSyCB-BfTGwDUrlmlJKC21oLu1a9N27pFlC8',
  elasticsearch: 'https://zqqhnd708a.execute-api.us-east-1.amazonaws.com/live',
  firebase: {
    apiKey: 'AIzaSyABcA0My-PZZi-QC5jIUSeE-c1nWhBL0Wk',
    authDomain: 'padspinv2.firebaseapp.com',
    databaseURL: 'https://padspinv2-default-rtdb.firebaseio.com',
    projectId: 'padspinv2',
    storageBucket: 'padspinv2.appspot.com',
    messagingSenderId: '385908763604',
    appId: '1:385908763604:web:9ff67574ef8f252f720441',
    measurementId: 'G-YFQSG057DF',
  } as FirebaseOptions,
  maxMapMarkersOnScreen: 400,
  origin: 'https://padspin.com',
  version: require('../../../../package.json').version,
  host: 'https://padspin.com',
};

export { webEnvironment as environment };
