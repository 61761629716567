import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Observable, Subject } from 'rxjs';
import firebase from 'firebase/compat';
import ControlParams = firebase.analytics.ControlParams;
import EventParams = firebase.analytics.EventParams;
import CustomParams = firebase.analytics.CustomParams;

declare let gtag: (
  command: string,
  eventName: string,
  eventParams?: unknown
) => void;

/** From firebase.analytics.EventNameString */
type EventNameString =
  | 'add_payment_info'
  | 'add_shipping_info'
  | 'add_to_cart'
  | 'add_to_wishlist'
  | 'begin_checkout'
  | 'checkout_progress'
  | 'exception'
  | 'generate_lead'
  | 'login'
  | 'page_view'
  | 'purchase'
  | 'refund'
  | 'remove_from_cart'
  | 'screen_view'
  | 'search'
  | 'select_content'
  | 'select_item'
  | 'select_promotion'
  | 'set_checkout_option'
  | 'share'
  | 'sign_up'
  | 'timing_complete'
  | 'view_cart'
  | 'view_item'
  | 'view_item_list'
  | 'view_promotion'
  | 'view_search_results';

export type PadspinEvent =
  | EventNameString
  | 'click_header_logo'
  | 'click_post_pad_form'
  | 'click_post_pad'
  | 'click_browse_pads'
  | 'click_social_media_link'
  | 'watch_marketing_video'
  | 'open_pad_booking_dialog'
  | 'close_pad_booking_dialog_form_incomplete'
  | 'close_pad_booking_dialog_registration_incomplete'
  | 'close_pad_booking_dialog_booking_complete'
  | 'pad_booking_form_valid'
  | 'submit_post_a_pad_form'
  | 'click_login'
  | 'click_sign_up'
  | 'book_showing_signup_completed'
  | 'submit_matching_form'
  | 'click_start_the_bidding';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  #events$ = new Subject<PadspinEvent>();
  event$: Observable<PadspinEvent> = this.#events$.asObservable();

  constructor(private readonly analytics: AngularFireAnalytics) {}

  async log(
    event: PadspinEvent,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
    eventParams?: { [key: string]: any }
  ): Promise<void> {
    this.#events$.next(event);
    await this.analytics.logEvent(event, eventParams);
  }

  async gtag(command: string, eventName: string, eventParams?: unknown) {
    try {
      gtag(command, eventName, eventParams);
    } catch (err) {
      console.log(`Error in sending gtag: ${err}`);
    }
  }

  async gtagConfig(
    targetId: string,
    config?: ControlParams | EventParams | CustomParams
  ) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      gtag('config', targetId, config);
    } catch (err) {
      console.log(`Error in sending gtag: ${err}`);
    }
  }
}
